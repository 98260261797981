<template>
  <v-dialog :value="listPaymentsModal" persistent fullscreen hide-overlay>
    <v-card>
      <v-data-table :items="payments" :headers="paymentHeaders">
        <template v-slot:[`item.date`]="{ item }">
          {{ toDateString(item.date) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ toCurrency(item.amount) }}
        </template>
      </v-data-table>      
    </v-card>
    <v-footer fixed class="d-flex justify-center">
        <v-btn-toggle>
          <v-btn @click="closeListPaymentsModal" color="primary">Sluit</v-btn>
        </v-btn-toggle>
      </v-footer>
  </v-dialog>
</template>

<script>
import { fetchGET, toCurrency, toDateString } from "../js/functions"
export default {
  name: "listPaymentsModal",
  props: {
    listPaymentsModal: Boolean,
    payMethod: String,
    fromDate: String,
    toDate: String
  },
  data() {
    return {
      payments: [],
      paymentHeaders: [
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Klant", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Bedrag", value: "amount" },
        { text: "Betalingswijze", value: "payMethod" }
      ]
    }
  },
  methods: {
    toDateString(val) {
      return toDateString(val)
    },
    toCurrency(val) {
      return toCurrency(val)
    },
    closeListPaymentsModal() {
      this.$emit("close-list-payments-modal")
    }
  },
  computed: {
    name() {
      return this.key
    }
  },
  async mounted() {
    let response = await fetchGET("listPayments", {
      payMethod: this.payMethod,
      fromDate: this.fromDate,
      toDate: this.toDate
    })
    this.payments = response
  }
}
</script>

<style scoped>
</style>