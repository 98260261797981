<template>
  <div>
    <v-row no-gutters="">
      <v-col cols="12" sm="2">
        <v-select
          label="Jaar"
          @change="getPaymentTotals"
          v-model="selectedYear"
          :items="years"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-menu
          ref="fromDatePicker"
          v-model="fromDatePicker"
          :close-on-content-click="false"
          :return-value.sync="fromDate"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="fromDateFormatted"
              @blur="fromDate = parseDate(fromDateFormatted)"
              label="Datum"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            scrollable
            locale="nl"
            first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fromDatePicker = false"
              >Annul.</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.fromDatePicker.save(fromDate)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="2">
        <v-menu
          ref="toDatePicker"
          v-model="toDatePicker"
          :close-on-content-click="false"
          :return-value.sync="toDate"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="toDateFormatted"
              @blur="toDate = parseDate(toDateFormatted)"
              label="t/m"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            scrollable
            locale="nl"
            first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="toDatePicker = false"
              >Annul.</v-btn
            >
            <v-btn text color="primary" @click="$refs.toDatePicker.save(toDate)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="2"
        ><v-select
          label="Type"
          @change="getPaymentTotals"
          :items="types"
          item-text="text"
          item-value="value"
          v-model="selectedType"
        ></v-select
      ></v-col>
      <v-col cols="12" sm="2"
        ><v-select
          label="Verkoper"
          @change="getPaymentTotals"
          :items="clerks"
          item-text="text"
          item-value="value"
          v-model="selectedType"
        ></v-select
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        sm="3"
        cols="12"
        v-for="payMethod of paymentTotals"
        :key="payMethod._id"
      >
        <v-card>
          <v-list dense>
            <v-card-title
              ><a @click="openListPaymentsModal(payMethod._id)">{{
                payMethod._id
              }}</a></v-card-title
            >
            <v-list-item> {{ toCurrency(payMethod.amount) }} </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            >Te factureren orders ouder dan
            <div style="width: 80px">
              <v-select
                class="pt-4 pl-1"
                dense
                @change="fetchOpenOrders"
                :items="daysOld"
                v-model="selectedDaysOld"
              ></v-select>
            </div>
            dagen</v-card-title
          >
          <v-data-table
            :items="openOrders"
            :headers="openOrdersHeaders"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [-1, 25, 50],
            }"
            class="elevation-1"
            item-key="_id"
          >
            <template v-slot:[`item.status`]="{ item }">
              <img :src="`/img/${item.status}.svg`" />
            </template>
            <template v-slot:[`item.date`]="{ item }">{{
              toDateString(item.date)
            }}</template>
            <template v-slot:[`item.grossTotal`]="{ item }">{{
              toCurrency(item.grossTotal)
            }}</template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-sheet>
            <canvas id="myChart" width="400" height="100"></canvas>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <list-payments-modal
      v-if="listPaymentsModal"
      :listPaymentsModal="listPaymentsModal"
      :payMethod="payMethod"
      :fromDate="fromDate"
      :toDate="toDate"
      @close-list-payments-modal="closeListPaymentsModal"
    ></list-payments-modal>
  </div>
</template>
<script>
import { fetchGET, toCurrency, toDateString } from "../js/functions"
import listPaymentsModal from '@/components/listPaymentsModal.vue'
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler
} from "chart.js"
Chart.register(
  LineElement,
  PointElement,
  LineController,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler
)
export default {
  name: "dashboard",
  data() {
    return {
      selectedYear: 2024,
      years: [2019, 2020, 2021, 2022, 2023, 2024],
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      fromDatePicker: false,
      toDatePicker: false,
      page: 0,
      itemsPerPage: 10,
      types: [
        { text: "Facturen", value: "invoicePaymentTotals" },
        { text: "Orders", value: "orderPaymentTotals" }
      ],
      clerks: [],
      selectedType: "invoicePaymentTotals",
      revenue: 0,
      paymentTotals: [
        { _id: "Kas", amount: 0 },
        { _id: "Pin", amount: 0 },
        { _id: "Bank", amount: 0 },
        { _id: "iDEAL", amount: 0 }
      ],
      daysOld: [10, 30, 60, 90, 120, 365],
      selectedDaysOld: 10,
      openOrders: [],
      openOrdersHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Totaal", value: "grossTotal" }
      ],
      months: [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December"
      ],
      chart: null,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Omzet",
            data: [],
            backgroundColor: "#053f66",
            borderColor: "rgba(2, 63, 186,1)",
            tension: 0.1,
            borderWidth: 1,
            fill: true
          }
        ]
      },
      listPaymentsModal: false,
      payMethod: ""
    }
  },
  methods: {
    toDateString(n) {
      return toDateString(n)
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    async getPaymentTotals() {
      this.paymentTotals = [
        { _id: "Omzet", amount: 0 },
        { _id: "Kas", amount: 0 },
        { _id: "Pin", amount: 0 },
        { _id: "Bank", amount: 0 },
        { _id: "iDEAL", amount: 0 }
      ]
      let data = {
        fromDate: this.fromDate,
        toDate: this.toDate
      }
      let paymentTotals = await fetchGET(this.selectedType, data)
      for (let payMethod of this.paymentTotals) {
        for (let payMethod2 of paymentTotals) {
          if (payMethod._id == payMethod2._id)
            payMethod.amount = payMethod2.amount
        }
      }
      this.fetchRevenueStats()
    },
    async fetchOpenOrders() {
      let data = { days: this.selectedDaysOld }
      this.openOrders = await fetchGET("openOrdersDaysOld", data)
    },
    async fetchRevenueStats() {
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
      let data = {
        year: this.selectedYear,
        fromDate: this.fromDate,
        toDate: this.toDate
      }
      let res = await fetchGET("fetchRevenueStats", data)
      let revenueStats = res.map(obj => {
        return { _id: obj._id - 1, grossTotal: obj.grossTotal }
      })
      for (let obj of revenueStats) {
        this.chartData.labels.push(this.months[obj._id])
        this.chartData.datasets[0].data.push(obj.grossTotal)
      }
      if (this.chart != null) this.chart.destroy()
      this.createChart()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    createChart() {
      var ctx = document.getElementById("myChart").getContext("2d")
      const data = this.chartData
      this.chart = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    },
    openListPaymentsModal(payMethod) {
      this.payMethod = payMethod
      this.listPaymentsModal = true
    },
    closeListPaymentsModal() {
      this.listPaymentsModal = false
    },
  },
  computed: {
    netRevenue() {
      let revenue = this.$store.state.orders.reduce(
        (acc, cur) => acc + parseFloat(cur.netTotal),
        0
      )
      return revenue
    },
    grossRevenue() {
      let revenue = this.$store.state.orders.reduce(
        (acc, cur) => acc + parseFloat(cur.grossTotal),
        0
      )
      return revenue
    },
    outstandingInvoices() {
      return this.$store.state.outstandingInvoices
    }
  },
  watch: {
    async fromDate(val) {
      try {
        this.fromDateFormatted = this.formatDate(val)
        if (this.fromDate > this.toDate) {
          this.toDateFormatted = this.formatDate(val)
          this.toDate = this.fromDate
        }
        await this.getPaymentTotals()
      } catch (e) {
        console.error(e)
      }
    },
    async toDate(val) {
      try {
        this.toDateFormatted = this.formatDate(val)
        if (this.fromDate > this.toDate) {
          this.toDateFormatted = this.formatDate(val)
          this.fromDate = this.toDate
        }
        await this.getPaymentTotals()
      } catch (e) {
        console.error(e)
      }
    }
  },
  async mounted() {
    try {
      await this.getPaymentTotals()
      await this.fetchOpenOrders()
      // await this.fetchRevenueStats()
      // this.createChart()
    } catch (e) {
      console.error(e)
    }
  },
  components: {
    listPaymentsModal,
  },
}
</script>
<style scoped>
</style>